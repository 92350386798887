export default {
    bind: (el, bind) => {
        let text = null;

        if (!el.nodeName === "IMG") {
            console.error("element should by IMG");
            return;
        }
        if ($(window).width() <= 999) {
            return;
        }

        window.dataModel = {};
        createModal();
        createModelContent();

        function createModal() {
            if ($("#scaleImage") && $("#scaleImage").length === 0) {
                let createModal = document.createElement("div");
                let createModalContent = document.createElement("div");

                createModalContent.id = "modal-content";
                createModal.id = "scaleImage";
                createModal.append(createModalContent);
                document.body.append(createModal);

                createModal.addEventListener("click", closeModal)
                // document.body.append($(`<div id='scaleImage' class="modal"><div id='modal-content' class="modal-content"></div></div> `).html())
            }
        }
        function getModalElements() {

            return {
                "modal": $("#scaleImage"),
                "modalContent": $("#modal-content")[0]
            }
        }


        function createModelContent() {

            el.addEventListener("click", function () {
                if (bind.value) {
                    text = bind.value;
                }
                if (text != null) {
                    let createModalContentDescription = document.createElement("div");
                    createModalContentDescription.id = "descriptionImage";
                    createModalContentDescription.innerHTML = text;
                    $("#modal-content").append(createModalContentDescription);
                }
                // document.body.style.pointerEvents = 'none';
                let modalObject = getModalElements();
                // document.body.style.overflow = 'hidden';
                let rect = this.getBoundingClientRect();
                let img = document.createElement('img');

                //start value
                window.dataModel.top = rect.top;
                window.dataModel.left = rect.left;
                window.dataModel.right = rect.right;
                window.dataModel.height = rect.height;
                window.dataModel.width = rect.width;


                //modalContent
                modalObject.modalContent.style.top = rect.top + "px";

                modalObject.modalContent.style.left = rect.left + "px";
                modalObject.modalContent.style.right = rect.right + "px";

                modalObject.modalContent.style.height = rect.height + "px";
                modalObject.modalContent.style.width = rect.width + "px";
                
                modalObject.modalContent.appendChild(img);
                
                
                img.src = this.src;
                img.style.backgroundColor =  "#fff";

                let heightImage = $(this).height();
                let widthImage = $(this).width();

                let newImage = new Image();
                newImage.src = img.src;

                newImage.onload = function () {
                    if (heightImage <= newImage.naturalHeight) {
                        heightImage = newImage.naturalHeight;
                        widthImage = newImage.naturalWidth;
                    }
                    if (widthImage <= newImage.naturalWidth) {
                        widthImage = newImage.naturalWidth;
                        heightImage = newImage.naturalHeight;
                    }

                    modalObject.modal.fadeIn();

                    const windowHeight = $(window).height();
                    const windowWidth = $(window).width();

                    const maxSizeHImg = windowHeight * .8;
                    const maxSizeWImg = windowWidth * .8;

                    if (widthImage >= maxSizeWImg) {

                        heightImage = (heightImage * maxSizeWImg) / widthImage;
                        widthImage = maxSizeWImg;
                        img.style.width = '100%';
                    }
                    if (heightImage >= maxSizeHImg) {
                        widthImage = (widthImage * maxSizeHImg) / heightImage;
                        heightImage = maxSizeHImg;
                        img.style.height = '100%';
                    } else {
                        img.style.width = '100%';
                    }
                    let sizeH = windowHeight / 2 - heightImage / 2;
                    let sizeW = windowWidth / 2 - widthImage / 2;
                    // return;

                    //end value
                    $(modalObject.modalContent).animate({
                        'top': sizeH + "px",
                        'left': sizeW + "px",
                        'right': sizeW + "px",
                        'height': heightImage + "px",
                        'width': widthImage + "px",
                    }, 500, function () {
                        if ($("#descriptionImage").length > 0) {
                            $("#descriptionImage").animate({
                                'opacity': 1,
                            }, 200);
                        }
                    });


                }

            })
        }

        function closeModal() {
            if ($("#descriptionImage").length > 0) {
                $("#descriptionImage").animate({
                    'opacity': 0,
                }, 200);
            }
            let modalObject = getModalElements();
            text = null;
            // document.body.style.pointerEvents = 'none';
            $(modalObject.modalContent).animate({
                'top': dataModel.top + "px",
                'left': dataModel.left + "px",
                'right': dataModel.right + "px",
                'height': dataModel.height + "px",
                'width': dataModel.width + "px",
            }, 500, function () {

                $(modalObject.modal).fadeOut();
                // setTimeout(function () {
                    document.body.style.pointerEvents = 'auto';
                    // document.body.style.overflow = 'unset';
                    if (modalObject.modalContent.children.length)
                        modalObject.modalContent.querySelector('img').remove();
                    if ($(modalObject.modalContent).find("#descriptionImage").length > 0) {
                        modalObject.modalContent.querySelector('#descriptionImage').remove();
                    }
                // }, 50);
            });
        };


    },
};



/* <div id='modal' class="modal-{$key}">
<div id='modal-content' class="modal-content-{$key}"></div>
</div> */
// window.modalGalleryAction = function(galleryId, swiperObject, modelObjectClass, modalClass){

//     if($("#"+galleryId).length){

//         const images = $("#"+galleryId).data("images")

//         const dataModel = {

//         };
//         const modal = $("."+modalClass)[0];

//         let modalContent = $("."+modelObjectClass)[0];

//         swiperObject.on('click', function (e) {
//             if($(window).outerWidth() <= 1000){
//                 return;
//             }
//             let div = this.clickedSlide;
//             if (div === undefined) return;

//             document.body.style.pointerEvents = 'none';
//             document.body.style.overflow = 'hidden';
//             let rect = div.getBoundingClientRect();
//             let img = document.createElement('img');

//             //start value
//             dataModel.top = rect.top;
//             dataModel.left = rect.left;
//             dataModel.right = rect.right;
//             dataModel.height = rect.height;
//             dataModel.width = rect.width;

//             modalContent.style.top = rect.top + "px";

//             modalContent.style.left = rect.left +  "px";
//             // modalContent.style.left = rect.left + (rect.width/2) + "px";
//             // modalContent.style.right = rect.right + (rect.width/2) + "px";
//             modalContent.style.right = rect.right + "px";

//             modalContent.style.height = rect.height + "px";
//             modalContent.style.width = rect.width + "px";

//             modalContent.appendChild(img);

//             img.src = images[this.clickedIndex].images.default;

//             // console.log($(div).height())
//             let heightImage = $(div).height();
//             let widthImage = $(div).width();

//             let newImage = new Image();
//             newImage.src = images[this.clickedIndex].images.default;

//             newImage.onload = function () {
//                 if (heightImage <= newImage.naturalHeight) {
//                     heightImage = newImage.naturalHeight;
//                     widthImage = newImage.naturalWidth;
//                 }
//                 if (widthImage <= newImage.naturalWidth) {
//                     widthImage = newImage.naturalWidth;
//                     heightImage = newImage.naturalHeight;
//                 }

//                 $(modal).fadeIn();

//                 const windowHeight = $(window).height();
//                 const windowWidth = $(window).width();

//                 const maxSizeHImg = windowHeight*.8;
//                 const maxSizeWImg = windowWidth*.8;

//                 if(widthImage >= maxSizeWImg){

//                     heightImage = (heightImage * maxSizeWImg) / widthImage; 
//                     widthImage = maxSizeWImg;
//                     img.style.width = '100%';
//                 }
//                 if(heightImage >= maxSizeHImg){
//                     widthImage = (widthImage * maxSizeHImg) / heightImage; 
//                     heightImage = maxSizeHImg;
//                     img.style.height = '100%';
//                 }else{
//                     img.style.width = '100%';
//                 }
//                 let sizeH = windowHeight / 2 - heightImage / 2;
//                 let sizeW = windowWidth / 2 - widthImage / 2;
//             // return;

//                 //end value
//                 $(modalContent).animate({
//                     'top': sizeH + "px",
//                     'left': sizeW + "px",
//                     'right': sizeW + "px",
//                     'height': heightImage + "px",
//                     'width': widthImage + "px",
//                 }, 600);

//             }


//             setTimeout(function () {
//                 document.body.style.pointerEvents = 'auto';
//             }, 700);
//         });





//         modal.onclick = function () {

//             document.body.style.pointerEvents = 'none';


//             $(modalContent).animate({
//                 'top': dataModel.top + "px",
//                 'left': dataModel.left + "px",
//                 'right': dataModel.right + "px",
//                 'height': dataModel.height + "px",
//                 'width': dataModel.width + "px",
//             }, 600, function () {
//                 $(modal).fadeOut();
//                 setTimeout(function () {
//                     document.body.style.pointerEvents = 'auto';
//                     document.body.style.overflow = 'unset';
//                     if (modalContent.children.length)
//                         modalContent.querySelector('img').remove();
//                 }, 500);
//             });
//         };
//     }
// }