export default {
    bind: el => {
        if (el.hasAttribute("data-src")) {
            el.classList.add("loading");
        }
    },
    inserted: el => {
        function loadImage() {
            // const imageElement = Array.from(el.children).find(
            //   el => el.nodeName === "IMG"
            // );
            const imageElement = el;
            if (!el.hasAttribute("data-src")) {
                return;
            }

            if (imageElement) {
                el.classList.add("loading");
                imageElement.addEventListener("load", () => {
                    setTimeout(() => el.classList.add("loaded"), 100);
                });
                imageElement.addEventListener("error", () => console.log("error"));
                imageElement.src = imageElement.dataset.src;
            }
        }

        function handleIntersect(entries, observer) {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver() {
            const options = {
                root: null,
                threshold: "0"
            };


            const observer = new IntersectionObserver(handleIntersect, options);

            observer.observe(el); // target element to watch
        }

        if (!window["IntersectionObserver"]) {
            loadImage();
        } else {
            createObserver();
        }
    }
};
