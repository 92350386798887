<template>
  <div class="app">
    <Banner></Banner>
    <Categories></Categories>
    <StandOut></StandOut>
    <Gallery></Gallery>
  </div>
</template>

<script>
export default {
  components: {
    Banner: () => import("./components/Banner.vue"),
    StandOut: () => import("./components/StandOut.vue"),
    Categories: () => import("./components/Categories.vue"),
    Gallery: () => import("./components/Gallery.vue")
  }
};
</script>

<style lang="scss">
.app {
  background-color: #F8F8F8;
  p {
    margin: 0;
  }
  img {
    transition: 0.2s;
    &.loading {
      filter: blur(20px);
    }
    &.loaded {
      filter: none;
    }
  }

}
  #scaleImage {
    display: none;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    /* opacity: 0; */
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
  }

  #modal-content {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    #descriptionImage{
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 16px;
      width: 100%;
      background-color: #ffffff;
      color :#212121;
      padding: 10px;
      p{
        margin: 0;
      }
    }
  }
</style>